import React from 'react'
import GoogleMapReact from 'google-map-react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import PropTypes from 'prop-types'

const MapMarker = ({ lat, lng }) => {
  return (
    <>
      <FaMapMarkerAlt
        size='42'
        color='#222'
        lat={lat}
        lng={lng}
        style={{ transform: `translate(-50%,-100%)` }}
      />
      <InfoWindow
        place={{
          name: 'Rand Medical Center',
          rating: 4.8,
          lat: 42.0985394,
          lng: -87.9600665,
          zoom: 12
        }}
      />
    </>
  )
}

// InfoWindow component
const InfoWindow = props => {
  const { place } = props
  const infoWindowStyle = {
    position: 'relative',
    bottom: 250,
    left: '-110px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100
  }

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>{place.name}</div>
      <div style={{ fontSize: 14 }}>
        <span style={{ color: 'grey' }}>{place.rating} </span>
        <span style={{ color: 'orange' }}>
          {String.fromCharCode(9733).repeat(Math.floor(place.rating))}
        </span>
        <span style={{ color: 'lightgrey' }}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}
        </span>
      </div>
      <br />
      <div style={{ fontSize: 14 }}>
        <span style={{ color: 'black' }}>(224) 735-3522</span>
        <br />
        <br />
        <span style={{ color: 'grey' }}>
          1925 E Rand Rd, Arlington Heights, IL 60004
        </span>
        <br />
        <a
          target='_blank'
          href='https://www.google.com/maps/place/Rand+Medical+Center/@42.0985394,-87.9600665,20z/data=!4m5!3m4!1s0x880fba4ce0a8eee3:0x8763654958c915e8!8m2!3d42.0984461!4d-87.9600838'
        >
          Show on Maps
        </a>
      </div>

      {/* <div style={{ fontSize: 14, color: 'grey' }}>{place.types[0]}</div> */}
      {/* <div style={{ fontSize: 14, color: 'grey' }}>
        {'$'.repeat(place.price_level)}
      </div> */}
      {/* <div style={{ fontSize: 14, color: 'green' }}>
        {place.opening_hours.open_now ? 'Open' : 'Closed'}
      </div> */}
    </div>
  )
}

const GoogleMap = ({ content, lat, lng, zoom, height }) => (
  <div style={{ width: `100%`, height: `${height || 750}px` }}>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyDntbGYzRu8lqdsOvp06pKC13kOknJOjOk'
      }}
      defaultCenter={{
        lat: content.lat || lat,
        lng: content.lng || lng
      }}
      defaultZoom={content.zoom || zoom}
    >
      <MapMarker
        lat={content.lat || lat}
        lng={content.lng || lng}
        show={true}
      />
    </GoogleMapReact>
  </div>
)

GoogleMap.defaultProps = {
  lat: 59.95,
  lng: 30.33,
  zoom: 12
}

// InfoWindow.propTypes = {
//   place: PropTypes.shape({
//     name: PropTypes.string,
//     formatted_address: PropTypes.string,
//     rating: PropTypes.number,
//     types: PropTypes.arrayOf(PropTypes.string),
//     price_level: PropTypes.number,
//     opening_hours: PropTypes.shape({
//       open_now: PropTypes.bool
//     })
//   }).isRequired
// }

// MapMarker.propTypes = {
//   show: PropTypes.bool.isRequired,
//   place: PropTypes.shape({
//     name: PropTypes.string,
//     formatted_address: PropTypes.string,
//     rating: PropTypes.number,
//     types: PropTypes.arrayOf(PropTypes.string),
//     price_level: PropTypes.number,
//     opening_hours: PropTypes.shape({
//       open_now: PropTypes.bool
//     })
//   }).isRequired
// }

export default GoogleMap
